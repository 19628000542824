import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Card, CardActions, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
//import {Button} from "livingwaters-lib";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';

const Privacy = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    return (
        <>
            {loaded?(
                <Container maxWidth="lx">
                    <SetPageTitle title="My Subscriptions" />
                    <Paper>
                        <Box p={2}>
                             <Typography component="h1" variant="h4">
                              {authUser.user  !== null &&
                                  <div>Hello {authUser.user.displayName}</div>
                              }
                              {authUser.user  === null &&
                                  <div>Welcome to Living Waters</div>
                              }
                             </Typography>
                        </Box>
                        {/*<Button label="Living Waters"/>*/}
                    </Paper>
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Privacy;
