import * as React from 'react';
import { useContext } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Outlet, useNavigate } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import { Avatar } from "@mui/material";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import InputBase from '@mui/material/InputBase';
//import VimeoPlayer from "./VimeoPlayer";
import { getAuth, signOut } from "firebase/auth";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
//import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
//import AddIcon from '@mui/icons-material/Add';
//import Fab from '@mui/material/Fab';
//import OpenTechPlustPlayer from "./OpenTechPlustPlayer";
import NurseQuickTools from './NurseQuickTools';
//import NestedList from "./NestedList";
//import SubMenu from "./SubMenu";
import DashboardSubMenu from './DashboardSubMenu';
import DashboardNestedList from './DashboardNestedList';
import { AuthContext, FireactContext } from '../lib/core';
import ImageIcon from '@mui/icons-material/Image';
import SubjectIcon from '@mui/icons-material/Subject';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import Stack from '@mui/material/Stack';
import { green, cyan, yellow, amber, lightGreen} from '@mui/material/colors';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AdminSubMenu from "./AdminSubMenu";
import AdminNestedList from "./AdminNestedList";

//https://mui.com/material-ui/react-app-bar/

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export const client = {};

function OpenTechPlusTemplate(props) {
  const { authUser } = useContext(AuthContext);
  //console.log("current user ", authUser);
  //console.log("template props ", props);
  //console.log("index ", props.extensionMenu.indexOf("groupAdmin"))
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {config} = useContext(FireactContext);
  const signInPath = config.pathnames.SignIn;
  const pathnames = config.pathnames;
  const profileUrl = pathnames.UserProfile;
  const accountUrl = pathnames.UserAccount;
  const subscriptionUrl = pathnames.ListSubscriptions;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith('/admin');
  const adminUrl = pathnames.Admin;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
      setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 0,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'center', width: '100%', p:2}}>
          <Avatar alt={authUser.user && authUser.user.displayName} src={authUser.user && authUser.user.photoURL?(authUser.user.photoURL):("https://ui-avatars.com/api/?name="+encodeURI(authUser.user && authUser.user.displayName)+"&background=007bff&size=64&color=f8f9fc")} style={{height:'32px',width:'32px'}} />
          <Typography component="span" sx={{ml:1}}>
            {authUser.user && authUser.user.displayName}
          </Typography>
        </Typography>
        <Divider/>
        {profileUrl && [
        <MenuItem key="profile" onClick={
            () => {
                handleMenuClose();
                navigate(profileUrl);
            }}>
             <IconButton
               size="small"
               aria-label="account of current user"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
             >
               <AccountBoxIcon />
             </IconButton>
             Profile
         </MenuItem>
        ]}
        {authUser.user && authUser.hasOwnProperty('isAdmin') && authUser.isAdmin === true && [
        <MenuItem key="admin" onClick={
            () => {
                handleMenuClose();
                navigate(adminUrl);
            }}>
             <IconButton
               size="small"
               aria-label="administrator panel"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
             >
               <ManageAccountsIcon />
             </IconButton>
             Admin
         </MenuItem>
        ]}
        {authUser.user && (<MenuItem key="logout" onClick={(e)=>{
          e.preventDefault();
          handleMenuClose();
          const auth = getAuth();
          signOut(auth).then(() => {
              document.location.href = "/";
          });
        }}>
           <IconButton
             size="small"
             aria-label="account of current user"
             aria-controls="primary-search-account-menu"
             aria-haspopup="true"
             color="inherit"
           >
             <ExitToAppIcon />
           </IconButton>
          Logout
        </MenuItem>)}
      </Menu>
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {authUser.user && (
        <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'center', width: '100%', px:2, pt:2, pb:1}}>
          <Avatar alt={authUser.user && authUser.user.displayName} src={authUser.user && authUser.user.photoURL?(authUser.user.photoURL):("https://ui-avatars.com/api/?name="+encodeURI(authUser.user && authUser.user.displayName)+"&background=007bff&size=64&color=f8f9fc")} style={{height:'32px',width:'32px'}} />
          <Typography component="span" sx={{ml:1}}>
            {authUser.user && authUser.user.displayName}
          </Typography>
        </Typography>
      )}
      <List>
      {!(authUser.user && authUser.userType === "Nurse") && (
          <>
          {authUser.user && (
            <Divider sx={{mb:1}}/>
          )}
          <NavLink to="/booking" style={{textDecoration:'none'}} key="booking">
            <ListItem key="booking" disablePadding>
              <ListItemButton>
                <ListItemIcon><EventAvailableIcon /></ListItemIcon>
                <ListItemText primary={<Typography color="textPrimary">Booking</Typography>} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          </>
      )}
      {!authUser.user && (
        <>
          <NavLink to="/client" style={{textDecoration:'none'}} key="client">
            <ListItem key="client" disablePadding>
              <ListItemButton>
                <ListItemIcon><CardGiftcardIcon /></ListItemIcon>
                <ListItemText primary={<Typography color="textPrimary">For Clients</Typography>} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink to="/provider" style={{textDecoration:'none'}} key="provider">
            <ListItem key="provider" disablePadding>
              <ListItemButton>
                <ListItemIcon><CardMembershipIcon /></ListItemIcon>
                <ListItemText primary={<Typography color="textPrimary">Become a Provider</Typography>} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </>
      )}
      </List>
      {/*<NestedList extensionMenu={props.extensionMenu} />*/}
      {authUser.user && (
        <DashboardNestedList/>
      )}
      {isAdminPath &&
        <AdminNestedList />
      }
      <Divider key="nested-list"/>
      {!authUser.user && [
         <NavLink to={signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash} style={{textDecoration:'none'}} key="signin">
           <ListItemButton>
               <ListItemIcon><LoginIcon /></ListItemIcon>
               <ListItemText primary={<Typography color="textPrimary">Sign In</Typography>} />
           </ListItemButton>
       </NavLink>
      ]}
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
         
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
      <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 0,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            },
          }}
        >
        {/*
         <MenuItem>
           <IconButton size="large" aria-label="show 4 new mails" color="inherit">
             <Badge badgeContent={4} color="error">
               <MailIcon />
             </Badge>
           </IconButton>
           Messages
         </MenuItem>
         <MenuItem>
           <IconButton
             size="large"
             aria-label="show 18 new notifications"
             color="inherit"
           >
             <Badge badgeContent={18} color="error">
               <NotificationsIcon />
             </Badge>
           </IconButton>
           Notifications
         </MenuItem>
         */}
         {profileUrl && [
         <MenuItem key="profile-mobile" onClick={
            () => {
                handleMenuClose();
                navigate(profileUrl);
            }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountBoxIcon />
              </IconButton>
              Profile
          </MenuItem>
         ]}
         {authUser.user && authUser.hasOwnProperty('isAdmin') && authUser.isAdmin === true && [
          <MenuItem key="admin-mobile" onClick={
            () => {
                handleMenuClose();
                navigate(adminUrl);
            }}>
              <IconButton
                size="large"
                aria-label="administrator panel"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <ManageAccountsIcon />
              </IconButton>
              Admin
          </MenuItem>
         ]}
         {authUser.user && (
         <MenuItem key="logout-mobile" onClick={(e)=>{
              e.preventDefault();
              handleMenuClose();
              const auth = getAuth();
              signOut(auth).then(() => {
                  document.location.href = "/";
              });
         }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <ExitToAppIcon />
              </IconButton>
              Logout
        </MenuItem>)}
       </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <NavLink to="/" style={{color: '#fff', textDecoration:'none'}} key="home">
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 0.1, display: { xs: 'none', md: 'block' } }}
              >
                <Stack direction="row" alignItems="center" sx={{color: lightGreen['A200']}}>
                <WaterDropIcon/> 
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ ml:1, mr:3}}
                  className='satisfy-regular'
                >
                  Living Waters
                </Typography>
                </Stack>
              </Typography>
          </NavLink>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {!(authUser.user && authUser.userType === "Nurse") && (
              <NavLink to="/booking" style={{textDecoration:'none'}} key="booking">
                  <Button key="booking" sx={{ color: '#fff', textTransform: 'none' }}>
                    Booking
                  </Button>
              </NavLink>
            )}
            {!authUser.user && (
              <>
              <NavLink to="/client" style={{textDecoration:'none'}} key="client">
                  <Button key="client" sx={{ color: '#fff', textTransform: 'none' }}>
                    For Clients
                  </Button>
              </NavLink>
              <NavLink to="/provider" style={{textDecoration:'none'}} key="provider">
                  <Button key="provider" sx={{ color: '#fff', textTransform: 'none' }}>
                    Become a Provider
                  </Button>
              </NavLink>
              </>
            )}
            {authUser.user && (
              <DashboardSubMenu/>
            )}
            {isAdminPath &&
              <AdminSubMenu />
            }
          </Box>
          <Box sx={{ flexGrow: 1 }} />
              <Box align='center' sx={{ display: { xs: 'flex', md: 'none' }, position: 'relative', marginRight: 2, marginLeft: 0, width: '100%' }}>
              <NavLink to="/" style={{color: 'inherit', textDecoration: 'none', width: '100%'}} key="landing">
                <Typography variant="h6" sx={{ my: 2, ml: 2 }}>
                  <Stack direction="row" alignItems="center" sx={{color: lightGreen['A700'], justifyContent: 'center'}}>
                      <WaterDropIcon/> 
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ ml:1, mr:3}}
                        className='satisfy-regular'
                      >
                        Living Waters
                      </Typography>
                  </Stack>
                </Typography>
              </NavLink>
          </Box>
          {!authUser.user && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button aria-label="sign in" color="inherit" sx={{textTransform: 'none'}} onClick={(e)=>{
                    e.preventDefault();
                    handleMenuClose();
                    document.location.href = signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash
                   }}>
                   <LoginIcon sx={{marginRight: "5px"}}/> Sign In
                </Button>
              </Box>
          )}
          {authUser.user && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {/*<IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>*/}
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {/*<AccountCircle />*/}
                  <Avatar alt={authUser.user && authUser.user.displayName} src={authUser.user && authUser.user.photoURL?(authUser.user.photoURL):("https://ui-avatars.com/api/?name="+encodeURI(authUser.user.displayName)+"&background=007bff&size=64&color=f8f9fc")} style={{height:'32px',width:'32px'}} />
                </IconButton>
              </Box>
          )}
          {authUser.user && (
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
          )}
        </Toolbar>
      </AppBar>
      {authUser.user && renderMobileMenu}
      {renderMenu}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
          <Box component="main" sx={{
              flexGrow: 1,
              backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900]
          }}>
              <DrawerHeader />
              <Outlet />
          {/*<VimeoPlayer embedId="16180248" />*/}
          {/*<div style={{position: 'relative'}}>
                  <Box mt={5} ml={3} mr={3} mb={3}>
                    <Outlet />
                  </Box>
            </div>*/}
            {/*authUser.userType === "Nurse" && <OpenTechPlustPlayer client={client}/>*/}
            {authUser.userType === "Nurse" && <NurseQuickTools />}
          </Box>
    </Box>
  );
}

OpenTechPlusTemplate.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default OpenTechPlusTemplate;
