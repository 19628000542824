import { Alert, List, ListItem, ListItemText, Box, Avatar, Typography, Divider, Paper, Container, Button, TablePagination, Stack, TextField, IconButton, ToggleButton, ToggleButtonGroup, Snackbar, Collapse } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useContext, useEffect, useState } from "react";
import { AuthContext, FireactContext, SetPageTitle } from "../../lib/core";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { collection, query, where, and, or, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, orderBy } from 'firebase/firestore';
import { getAuth, sendEmailVerification } from "firebase/auth";
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import CircularProgress from '@mui/material/CircularProgress';

import {AdminPageContext} from "./AdminPageContext";

export const AdminEarnings = () => {
    const auth = getAuth();
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [ prompt, setPrompt ] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);

    const [subscriptionsData, setSubscriptionsData] = useState(null);
    const [tipsData, setTipsData] = useState(null);

    const { admin } = useContext(AdminPageContext);
    dayjs.extend(localizedFormat);

    //console.log('authUser ', authUser);
    console.log('admin', admin);

    useEffect(() => {
        if (admin == 'super') {
                setLoaded(true);
        } else {
            setLoaded(true);
        }
    }, [auth.currentUser.uid, admin, functionsInstance, firestoreInstance]);
    
    return (<>
        {loaded ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} 
                    sx={{
                        '--Grid-borderWidth': '1px',
                        borderTop: 'var(--Grid-borderWidth) solid',
                        borderLeft: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                        },
                    }}
                >
                    <Grid size={{ xs: 12, md: 12 }}>
                        {error !== null &&
                            <Alert severity="error">{error}</Alert>
                        }
                        {prompt !== null &&
                            <Alert severity="info">
                                {prompt}
                            </Alert>
                        }
                    </Grid>
                    <Grid size={{ xs: 12, md: 8 }}>
                        <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                            {admin == 'super' && (
                                    <>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={12}>
                                            <Box sx={{p:2}}>
                                                Website Earnings
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    </>
                            )}
                            {admin == 'manager' && (
                                <Box sx={{height: '70vh', p: 10, alignContent: 'center', textAlign: 'center'}}>
                                    Business Co-operator Access Permission
                                </Box>
                            )}
                            {admin == '' && (
                                <>
                                    Access Permission is required.
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="left" alignItems="center">
                        <Typography>
                        Access Level - {admin}    
                        </Typography>
                    </Grid>
                    {admin == 'super' && (
                        <Box sx={{ flexGrow: 1 }}>
                            Admin Earnings
                        </Box>
                    )}
                </Grid>
            </Box>
        ) : (
            <Box sx={{width: '100%', p: 5, textAlign:'center'}}><CircularProgress /></Box>
        )}
    </>)
}
