import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Paper, Typography, styled } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";
import {add, subtract} from "livingwaters";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
}));

const Faq = ({loader}) => {

    const { firebaseApp, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);

    const postCategory = 'faq';
    const [posts, setPosts] = useState(null);
    const [expanded, setExpanded] = React.useState(true);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    
    //console.log('authUser ', authUser);
    //console.log('npm', add(2,6), subtract(2,1));

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const defaultPermissions = [];
        const adminPermissions = [];
        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }

        const getPosts = async (postCategory) => {
            //get posts by category
            const postsRef = collection(firestoreInstance, '/posts');
            const postsQuery = query(postsRef, where('category', '==', postCategory));
            const postsRes = [];
            var post_id = null;
            var data = null;
    
            await getDocs(postsQuery).then(async postSnapshot => {
                postSnapshot.forEach(record => {
                    post_id = record.id;
                    data = record.data();
                    postsRes.push({post_id: post_id, post:data});
                });
                
            }).catch(err => {
                console.log('error', err);
            });  
    
            //console.log('postsRes', postsRes);
            setPosts(postsRes);
        }
        getPosts(postCategory);  
        setLoaded(true);
        //console.log('fire', firestoreInstance);
    },[firebaseApp, authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded ?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="FAQ" />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', backgroundColor: '#FFF'}} >
                    <Grid container spacing={0}>
                        <Grid size={{ xs: 12, md: 3 }}>
                            <Box sx={{ width: '100%' }}>
                            <Typography variant="body1" align="center" gutterBottom>
                                <Stack alignItems="center" justifyContent="space-between" direction="row" spacing={2}>
                                    <Stack alignItems="center" direction="row" spacing={2}>
                                        <FormatListBulletedIcon/>
                                        <span>Contents</span>
                                    </Stack>
                                    <ExpandMore
                                        expand={expanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                        sx={{marginLeft:'auto'}}
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Stack>
                            </Typography>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <SimpleTreeView>
                                <TreeItem itemId="getting-started" label="Getting Started">
                                <TreeItem itemId="what-is-living-waters" label="What is Living Waters" />
                                <TreeItem itemId="types-of-services" label="What types of services does Living Waters offer?" />
                                <TreeItem itemId="how-to-create-account" label="How do I create a Living Waters Account" />
                                </TreeItem>
                                <TreeItem itemId="booking-an-appointment" label="Booking an appointment">
                                <TreeItem itemId="instant-book-with-a-provider" label="Instant Book With A Provider" />
                                <TreeItem itemId="services-available" label="Services available" />
                                <TreeItem itemId="checking-your-provider-status" label="Checking your Provider's status"/>
                                <TreeItem itemId="updating-your-address" label="Updating your address" />
                                </TreeItem>
                                <TreeItem itemId="updating-your-account" label="Updating Your Account">
                                <TreeItem itemId="change-or-reset-your-password" label="Change or reset your password" />
                                <TreeItem itemId="updating-your-email-and-phone-number" label="Updating your email and phone number" />
                                <TreeItem itemId="delete-your-account" label="Delete your account" />
                                </TreeItem>
                                <TreeItem itemId="service-provider" label="Service Provider">
                                <TreeItem itemId="verify-your-license" label="Verify your license" />
                                <TreeItem itemId="set-your-service-area" label="Set your service area" />
                                <TreeItem itemId="manage-your-agenda" label="Manage your agenda" />
                                </TreeItem>
                            </SimpleTreeView>
                            </Collapse>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 9 }}>
                        <Box sx={{maxHeight: '100vh', overflow: 'auto', p: 2}} borderLeft={{xs:0, md:'1px solid #E1E1E1'}} borderTop={{md:0, xs:'1px solid #E1E1E1'}} borderColor="#E1E1E1">
                            {posts && (
                                <>
                                {posts.map((post) => (
                                    <div dangerouslySetInnerHTML={{ __html: post.post.text }} />
                                ))}
                                </>
                            )}
                        </Box>
                        </Grid>
                    </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Faq;
