import React, { useContext, useState, useEffect } from "react";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { AuthContext, FireactContext } from "../lib/core";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import WebIcon from '@mui/icons-material/Web';
//import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import Divider from '@mui/material/Divider';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

export default function AdminNestedList(props) {
  const { authUser, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const [openManageGroup, setOpenManageGroup] = React.useState(true);
  const {config} = useContext(FireactContext);
  const pathnames = config.pathnames;
  const adminHomeUrl = pathnames.Admin;
  const adminUsersUrl = pathnames.AdminWebsiteUsers;
  const adminCouponsUrl = pathnames.AdminCoupons;
  const adminEarningsUrl = pathnames.AdminEarnings;

  const handleClick = () => {
    setOpenManageGroup(!openManageGroup);
  };

  return (
    <>
    {true && (<>
      <Divider key="nested-list-group"/>
       <List
         sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
         component="nav"
         aria-labelledby="nested-list-subheader"
         subheader={
           <ListSubheader component="div" id="nested-list-subheader">
            Admin Console
           </ListSubheader>
         }
       >
          {true && [
              <NavLink to={adminHomeUrl} style={{textDecoration:'none'}} key="admin-home">
                  <ListItemButton>
                      <ListItemIcon><GridViewOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Dashboard</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {true && [
              <NavLink to={adminEarningsUrl} style={{textDecoration:'none'}} key="admin-earnings">
                  <ListItemButton>
                      <ListItemIcon><PaidOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Earnings</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {true && [
              <NavLink to={adminUsersUrl} style={{textDecoration:'none'}} key="admin-users">
                  <ListItemButton>
                      <ListItemIcon><GroupIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Users</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
          {true && [
              <NavLink to={adminCouponsUrl} style={{textDecoration:'none'}} key="admin-coupons">
                  <ListItemButton>
                      <ListItemIcon><LocalActivityIcon /></ListItemIcon>
                      <ListItemText primary={<Typography color="textPrimary">Coupons</Typography>} />
                  </ListItemButton>
              </NavLink>
          ]}
       </List>
      </>)}
    </>
  );
}
