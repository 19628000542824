import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import {PageSection} from "../template/TemplateElements";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, updateDoc, orderBy, startAt, endAt, and, or, limit, onSnapshot, documentId, Timestamp } from 'firebase/firestore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import {CustomizedInputBase} from "./util";
import './Chat.css';

const Messaging = (props) => {
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    const [messages, setMessages] = useState([]); 
    const [messageContacts, setMessageContacts] = useState([]); 

    const unsubscribeToChat = useRef();
    const currentClient = useRef();

    const subscribeToNewMessages = () => {
        if (currentClient.current && currentClient.current.uid) {
            if (unsubscribeToChat.current) {
                //console.log('unsubscribe');
                unsubscribeToChat.current();
            }
            const messagesRef = collection(firestoreInstance, '/messages');
            const messageQuery = query(messagesRef, 
                    or(
                    and(where('receiver', '==', currentClient.current.uid), where('sender', '==', authUser.user.uid)),
                    and(where('receiver', '==', authUser.user.uid), where('sender', '==', currentClient.current.uid))
                    ),
                    orderBy("datetime", "desc"), // this is to enable retrieving the latest messages
                    limit(100),
                    //orderBy("datetime", "asc"), //remember to build index
                );
    
            unsubscribeToChat.current = onSnapshot(messageQuery, (querySnapshot) => {
            messages.current = [];
            querySnapshot.forEach((doc) => {
                messages.current.push({
                id: doc.id,
                ...doc.data(),
                });
            });
        
            setMessages(messages.current);
    
            //console.log("authUser.user.uid", authUser.user.uid, "messages.current", messages.current);
            });
    
            //console.log('unsubscribe', unsubscribeToChat.current);
        }
    }

    useEffect(() => {
        props.updatePosition();
    }, [messages]);

    const chatWithClient = (sender) => {
        console.log('chat with', sender);
        currentClient.current = sender;
        props.setShowContacts(false);
        subscribeToNewMessages();
        //props.updatePosition();
        /*
        console.log('chat with ref', ref.current);
        if (ref.current && ref.current.ownerDocument) {
            console.log('chat with scroll', ref.current.ownerDocument);
            ref.current.ownerDocument.documentElement.scrollTop = 100;
            //ref.current.ownerDocument.body.scrollTop = ref.current.ownerDocument.body.offsetHeight;
        }*/
    }

    const loadContacts = () => {
        const sendersRef = collection(firestoreInstance, '/messageSenders');
        const usersRef = collection(firestoreInstance, '/users');
        const sendersQuery = query(sendersRef, 
            where('receiver', '==', authUser.user.uid)
        );

        onSnapshot(sendersQuery, (querySnapshot) => {
            const senderUids = [];
            const senders = [];
            querySnapshot.forEach((doc) => {
                senderUids.push(doc.data().sender);
                senders.push(doc.data());
            });
            
            if (senderUids.length > 0) {
                const usersQuery = query(usersRef, where(documentId(), 'in', senderUids));
                getDocs(usersQuery).then(usersSnapshot => {
                    const users = [];
                    usersSnapshot.forEach(user => {
                        const sender = user.data();
                        const s = senders.find((sender) => {
                            return sender.sender === user.id;
                        });
                        sender.lastText = s.lastText;
                        sender.uid = user.id;
                        users.push(sender);
                        //console.log("sender ", sender);
                    });
                    setMessageContacts(users);
                }).catch(err => {});
            }
        });
    }

    useEffect(() => {
        if (authUser.user) {
            loadContacts();
        }
    }, []);

    const messageInputRef = useRef();

    const sendMessage = async () => {
        const messagesRef = collection(firestoreInstance, '/messages');
        
        const docRef = await addDoc(messagesRef, {
        receiver: currentClient.current.uid,
        sender: authUser.user.uid,
        text: messageInputRef.current.value,
        datetime: Timestamp.now(),
        });

        messageInputRef.current.value = '';

        /*console.log('message sent', {
        receiver: props.dialogInfo.user_id,
        sender: authUser.user.uid,
        text: messageInputRef.current.value,
        datetime: Timestamp.now(),
        });*/
    }

    return (
        <>
        {props.showContacts ? (
            <PageSection>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {messageContacts.map((item, index) => (
                    <>
                    <ListItem alignItems="flex-start" onClick={()=>{chatWithClient(item);}}>
                        <ListItemAvatar>
                            <div>
                                <Avatar alt={item.displayName} src={item.photoURL} sx={{cursor: 'pointer'}} />
                            </div>
                        </ListItemAvatar>
                        <ListItemText
                        primary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline', cursor: 'pointer' }}
                                component="span"
                                variant="subtitle2"
                                color="text.primary"
                            >
                                {item.displayName}
                            </Typography>
                            </React.Fragment>
                        }
                        secondary={
                            <React.Fragment>
                            {item.lastText}
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    {index<(messageContacts.length-1) && <Divider variant="inset" component="li" />}
                    </>        
                ))}
                </List>
            </PageSection>
        ) : (
            <PageSection>
                <Box sx={{ pb: 7 }}>
                <CssBaseline />
                <div className="message-container"> 
                    {messages.slice().reverse().map((item) => (
                        <div className={`message ${item.sender == authUser.user.uid ? 'receiver-message':'sender-message'}`}> 
                        {item.sender != authUser.user.uid &&
                            <img className="avatar" src={currentClient.current.photoURL}/>
                        }
                        {item.text}
                        </div>
                    ))}
                </div>
                <Paper sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation>
                        <CustomizedInputBase sendMessage={sendMessage} inputRef={messageInputRef} />
                    </BottomNavigation>
                </Paper>
                </Box>
            </PageSection>
        )}
        </>
    )
}

export default Messaging;